import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import CreateFinaceAccountType from "./pages/Login/CreateFinaceAccountType";
import PostLogin from "./Layout/PostLogin";
import Registration from "./pages/Registration";
import CrmUsers from "./pages/CRM/Users";
import Monitoring from "./pages/CRM/Monitoring";
import WaitingList from "./pages/Waitinglist";
import WalletScan from "./pages/WalletScan";
import Comparison from "./pages/Comparison";
import CpaAdminDashboard from "./pages/CpaAdmin/Dashboard";
import TaskManager from "./pages/CpaAdmin/TaskManager";
import CpaInput from "./pages/CpaAdmin/Input";
import CpaOutput from "./pages/CpaAdmin/Output";
import CpaAdminProfileCreation from "./pages/CpaAdminProfileCreation/CpaAdminProfileCreation";
import ClientProfileCreation from "./pages/ClientProfileCreation/ClientProfileCreation";
import Independent from "./pages/IndependentProfilecreation/IndependentProfilecreation";
import EmployeeProfile from "./pages/EmployeeProfileCreation/EmployeeProfileCreation";
import ComparisonPage from "./pages/Comparison/ComparisonPage";
import GlobalContextProvider from "./context/Globalcontext";
import { GlobalContexWalletProvider } from "./pages/WalletScan/globalContext";
import ContextWalletProvider from "./pages/WalletScan/globalComponents/Context/Context";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./pages/HomeMain";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <GlobalContextProvider>
          <GlobalContexWalletProvider>
            <ContextWalletProvider>
              <BrowserRouter>
                <Switch>
                  <Route path="/" component={HomePage} exact />
                  <Route path="/registration" component={Registration} />
                  <Route path="/waitinglist" component={WaitingList} />
                  <Route
                    path="/comparison"
                    extract
                    component={ComparisonPage}
                  />
                  <Route
                    path="/comparison/:companyname"
                    component={Comparison}
                  />
                  <Route path="/login" component={Login} />
                  <Route
                    path="/createFinace"
                    component={CreateFinaceAccountType}
                  />
                  <Route
                    path="/cpa/profileCreation"
                    component={CpaAdminProfileCreation}
                  />
                  <Route
                    path="/client/profileCreation"
                    component={ClientProfileCreation}
                  />
                  <Route
                    path="/independent/profileCreation"
                    component={Independent}
                  />
                  <Route
                    path="/employee/profileCreation"
                    component={EmployeeProfile}
                  />
                  <Route path="/dashboard" component={PostLogin} />
                  <Route
                    path="/admin/crm/users"
                    render={(props) => (
                      <PostLogin {...props} child={<CrmUsers />} />
                    )}
                  />
                  <Route
                    path="/admin/crm/monitoring"
                    render={(props) => (
                      <PostLogin {...props} child={<Monitoring />} />
                    )}
                  />
                  <Route
                    path="/cpaadmin/finace/dashboard"
                    render={(props) => (
                      <PostLogin {...props} child={<CpaAdminDashboard />} />
                    )}
                  />
                  <Route
                    path="/cpaadmin/finace/input"
                    render={(props) => (
                      <PostLogin {...props} child={<CpaInput />} />
                    )}
                  />
                  <Route
                    path="/cpaadmin/finace/output"
                    render={(props) => (
                      <PostLogin {...props} child={<CpaOutput />} />
                    )}
                  />
                  <Route
                    path="/cpaadmin/finace/taskmanager"
                    render={(props) => (
                      <PostLogin {...props} child={<TaskManager />} />
                    )}
                  />
                  <Route
                    path="/clients/finaceured/walletscan"
                    render={(props) => (
                      <PostLogin {...props} child={<WalletScan />} />
                    )}
                  />
                  <Route path="*" component={Login} />
                </Switch>
                <ToastContainer />
              </BrowserRouter>
            </ContextWalletProvider>
          </GlobalContexWalletProvider>
        </GlobalContextProvider>
      </React.StrictMode>
    </QueryClientProvider>
  );
}

export default App;
